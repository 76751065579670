<template>
    <div class='affiliate-header'>
            <img src='../../public/Invrs-Logo-Green-Blue.png' width='250' style="cursor: pointer;" @click="goHome()">
            <Button label="Logout" class="p-button-text affiliate-button" @click="logout()" />
    
    </div>

    <div class="p-grid">
        <div class="p-col-12">
            <div class="card" style="text-align:center;">
                <h5 style="float:left;">Affiliate Stats for {{affiliateName}}</h5>
                <DataTable :value="stats" class="p-datatable-customers" :lazy="true" :loading="loadingStats" >
                                <!-- <LoadingSpinner :loading='loadingStats' v-if='loadingStats' /> -->
                    <Column header="Campaign">
                        <template #body="slotProps">
                            {{ slotProps.data.campaign.name }}<br>
                            <small>Running  {{formatStartDate(slotProps.data.campaign.startDate)}} - {{formatStopDate(slotProps.data.campaign.stopDate)}}</small>
                            
                        </template>
                    </Column>
                    <Column header="Referral Stats">
                        <template #body="slotProps">
                            
                            <table>
                                <tr><td><b>Total Referrals:</b></td><td><b>{{slotProps.data.affiliateData.counters.referrals.total}}</b></td></tr>
                                <tr><td >&nbsp;</td><td >&nbsp;</td></tr>
                                <tr><td>facebook: </td><td>{{slotProps.data.affiliateData.counters.referrals.facebook}}</td></tr>
                                <tr><td>twitter: </td><td>{{slotProps.data.affiliateData.counters.referrals.twitter}}</td></tr>
                                <tr><td>reddit: </td><td>{{slotProps.data.affiliateData.counters.referrals.reddit}}</td></tr>
                                <tr><td>whatsapp: </td><td>{{slotProps.data.affiliateData.counters.referrals.whatsapp}}</td></tr>
                               <tr><td>linkedin: </td><td>{{slotProps.data.affiliateData.counters.referrals.linkedin}}</td></tr>
                                <tr><td>email: </td><td>{{slotProps.data.affiliateData.counters.referrals.email}}</td></tr>
                                <tr><td>copy: </td><td>{{slotProps.data.affiliateData.counters.referrals.copy}}</td></tr>
                            </table>

                            
                        </template>
                    </Column>
                    <Column header="Share Stats">
                        <template #body="slotProps">
                             <table>
                                <tr><td><b>Total Shares:</b></td><td><b>{{slotProps.data.affiliateData.counters.shares.total}}</b></td></tr>
                                <tr><td >&nbsp;</td><td >&nbsp;</td></tr>
                                <tr><td>facebook: </td><td>{{slotProps.data.affiliateData.counters.shares.facebook}}</td></tr>
                                <tr><td>twitter: </td><td>{{slotProps.data.affiliateData.counters.shares.twitter}}</td></tr>
                                <tr><td>reddit: </td><td>{{slotProps.data.affiliateData.counters.shares.reddit}}</td></tr>
                                <tr><td>whatsapp: </td><td>{{slotProps.data.affiliateData.counters.shares.whatsapp}}</td></tr>
                                <tr><td>linkedin: </td><td>{{slotProps.data.affiliateData.counters.shares.linkedin}}</td></tr>
                                <tr><td>email: </td><td>{{slotProps.data.affiliateData.counters.shares.email}}</td></tr>
                                <tr><td>other: </td><td>{{getOtherShares(slotProps.data.affiliateData.counters.shares)}}</td></tr>
                                
                            </table>
                        </template>
                    </Column>
                </DataTable>
                <Button label="Refresh" class="p-button-text affiliate-button" @click="refreshStats()" style="margin-top:5px;"/>
                
            </div>
        </div>
    </div>
</template>

<script>
import UserService from '../service/UserService';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import format from 'date-fns/format';
// import LoadingSpinner from '../components/common/LoadingSpinner.vue';

export default {
    name: 'AffiliateHome',
    
    components: {
        DataTable, Column,
        // LoadingSpinner,
    },

    data() {
        return {
            loadingStats: false,
            stats: [],
            affiliateData: null,
        };
    },
    computed: {
        affiliateName() {
            if( this.$store.state.affiliate) {
                return this.$store.state.affiliate.email;
            }
            else {
                return "";
            }
            
        }
    },
    mounted() {
        if (!this.$store.state.authorized || this.$store.state.affiliate == null){
            console.log("not authorized affiliate")
            document.location.href = '/index.html#/affiliate/login';

        }
        else {
            console.log("affiliate authorized");
            this.refreshStats();
        }
    },
    methods: {
        formatStartDate(dateStr) {
            //Started May 31, 2021 12:00:00 AM
            let d = new Date(dateStr);
            return format(d, "yyyy-MM-dd");
        },
        formatStopDate(stopDate) {
            if( stopDate ) {
                let d = new Date(stopDate);
                return format(d, "yyyy-MM-dd");
            }
            else {
                return "";
            }
        },
        getOtherShares(shares) {
            let calcTotal = shares.facebook + shares.twitter + shares.reddit + shares.whatsapp + shares.linkedin + shares.email;
            if( calcTotal < shares.total) {
                return shares.total - calcTotal;
            }
            else {
                return 0;
            }
        },
        getAffiliateName() {
            if( this.affiliateData ){
                console.log(JSON.stringify(this.affiliateData))
                return this.affiliateData.user.email +"("+this.affiliateData.user.firstname+")";
            }
            else{
                return "";
            }
        },
        refreshStats() {
             this.loadingStats = true;
            UserService.getAffiliateStats().then( resp => {
                if( resp.data.status == "success"){
                    //console.log(JSON.stringify(resp.data.campaigns));
                    this.stats = resp.data.campaigns;
                    this.affiliateData = resp.data.affiliateData;
                }else {
                     console.log("error getting stats " + JSON.stringify(resp.data));
                }
                this.loadingStats = false;
            });
        },
        logout() {
            this.$store.commit("SET_TOKEN", null);
            this.$store.commit("SET_AFFILIATE", null);
            document.location.href = '/index.html#/affiliate/login';

            //this.$router.push( {path:"/affiliate/login"});
        }
       
    },
};
</script>

<style scoped>
* {
    font-family: "Trebuchet MS", "Verdana";
}

.affiliate-header {
    display: flex;
    align-items: center;
    padding: 10px 35px 30px;
}
.p-button-text {
    font-weight: bold;
    color: #32364e;
}
.p-button-text:enabled:hover {
    background: none;
    color: #33CC99;
}
.p-button-text:enabled:focus {
    box-shadow: none;
    border: none;
}

.affiliate-button {
    margin-left: auto;
    font-size: 25px;
    padding: 9px 15px;
}

.affiliate-container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 200px auto auto auto;
}

.affiliate-title {
    font-size: 60px;
    color: #32364e;
    padding-bottom: 0.7em;
}

.input-container {
    display: flex;
    padding-bottom: 35px;
}

.p-inputtext {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}
.p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    width: 50%;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.button-container {
    display: flex;
}

.affiliate-button {
    margin-left: auto;
   
    border-radius: 30px;
    background-color: #33CC99;
    font-size: 12px;
    padding: 9px 15px;
    color: white;
    border: 1px solid #33CC99;
    justify-content: center;
}
.affiliate:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

</style>